<template>
  <div>
    <div class="topWrap">
      <div class="content">
        <h3>登录</h3>
        <el-form
          :model="form"
          :rules="rules"
          ref="ruleForm"
          :show-message="false"
        >
          <el-form-item prop="mobileOrEmail">
            <el-input v-model="form.mobileOrEmail" placeholder="请输入手机号码">
              <i slot="prefix" class="iconfont"> &#xe60a; </i>
            </el-input>
          </el-form-item>
          <el-form-item prop="pwd">
            <el-input
              v-model="form.loginPwd"
              type="password"
              placeholder="请输入登录密码"
            >
              <i slot="prefix" class="iconfont"> &#xe607; </i>
            </el-input>
          </el-form-item>
          <!-- <el-form-item prop="verifyCode">
                  <el-input v-model="form.verifyCode" placeholder="请输入短信验证码">
                    <i  slot="prefix" class="iconfont"> &#xe609; </i>
                    <em slot="suffix" class="yzm"> 发送验证码 </em>
                  </el-input>
                </el-form-item> -->
          <!-- <el-form-item prop="allow">
                     <el-checkbox v-model="form.allow">记住密码</el-checkbox>
                 </el-form-item> -->
          <el-form-item>
            <el-button type="primary" @click="onSubmit">登录</el-button>
          </el-form-item>
        </el-form>

        <div class="goLogin">
          <router-link :to="{ path: '/Rigist', query: {} }">
            没有账号,去注册
          </router-link>
          <router-link :to="{ path: '/ForgetPwd', query: {} }">
            忘记密码
          </router-link>
        </div>
      </div>
    </div>
    <service-block></service-block>
    <superiority-block></superiority-block>
    <guarantees-block></guarantees-block>
    <record-block></record-block>

  </div>
</template>

<script>
import ServiceBlock from "@/components/ServiceBlock";
import SuperiorityBlock from "@/components/SuperiorityBlock";
import GuaranteesBlock from "@/components/GuaranteesBlock";
import RecordBlock from '@/components/RecordBlock'
import { slider, slideritem } from "vue-concise-slider";

import { login } from "@/api/UserService";
import { sendSmsVerifyCode } from "@/api/SmsService";
export default {
  name: "Login",
  components: {
    ServiceBlock,
    SuperiorityBlock,
    GuaranteesBlock,
    RecordBlock,
    slider,
    slideritem,
  },
  mounted() {
    this.init();
  },
  data() {
    return {
      redirect:this.$route.query.redirect,
      form: {
        mobileOrEmail: "",
        verifyCode: "",
        loginPwd: "",
      },
      rules: {
        name: [
          // { required: true, message: '请输入昵称', trigger: 'blur' }
        ],
      },
    };
  },

  methods: {
    init() {},
    onSubmit() {
      login(this.form).then(({ data, res }) => {
        console.log(data, res);
        this.$store.commit("login", {
          userInfo: data,
          token: res.headers.get("Authorization"),
        });
        this.bus.$emit("refreshInfo");
        this.bus.$emit("refreshVip");
        this.bus.$emit("refreshObligee");
        if(this.redirect){
          this.$router.push(this.redirect);
        }else{
          this.$router.push("/");
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../assets/css/loginRigist.scss";
</style>